import $ from 'jquery';
import { translate } from '@elements/translations';
import { showNotification } from '@elements/alert-notification';
// import {trackGenericEvent} from "../../scripts/tracking";

const defaultOptions = {
    activeIds: [],
    routeAdd: '',       //Todo @Backend set route
    routeRemove: '',    //Todo @Backend set route
    notificationOptions: {
        $container: $('.js-wishlist__notification')
    }
};
const defaultSelectors = {
    item: '.js-wishlist__btn',
    itemId: 'wishlist-id',
    wishlistId: 'wishlist-list',
    itemActiveClass: 'is-active'
};

export function createWishlist(options = defaultOptions, selectors = defaultSelectors) {
    let numberOfActiveWishlistItems = window['_wishlist'] && window['_wishlist'].wishlist.activeIds.length;

    options = {
        ...defaultOptions,
        ...options
    };
    selectors = {
        ...defaultSelectors,
        ...selectors
    };

    let _requests = {};


    const addToWishlist = function(id, params = {}) {
        if(_requests[id]){
            return;
        }

        if (!Array.isArray(options.activeIds)) {
            options.activeIds = [];
        }

        options.activeIds.push(id);
        notifyCallbacks('adding');

        _requests[id] = $.ajax({
            url: options.routeAdd,
            type: 'get',
            data: {
                id: id,
                ...params
            }});
        showNotification(_requests[id], options.notificationOptions);
        _requests[id].then(function (res) {
            options.activeIds = res.activeIds;
            notifyCallbacks('added');
        }).catch(function () {
            options.activeIds.splice(options.activeIds.indexOf(id), 1);
            notifyCallbacks('add-failed');
        }).always(function () {
            _requests[id] = null;
        });

        return _requests[id];
    };

    const removeFromWishlist = function(id, params = {}) {
        if(_requests[id]){
            return;
        }

        if (!Array.isArray(options.activeIds)) {
            options.activeIds = [];
        }

        const index = options.activeIds.indexOf(id);
        if (index !== -1) {
            options.activeIds.splice(index, 1);
        }
        notifyCallbacks('removing');

        _requests[id] = $.ajax({
            url: options.routeRemove,
            type: 'get',
            data: {
                id: id,
                ...params
            }});
        showNotification(_requests[id], options.notificationOptions);
        _requests[id].then(function (res) {
            options.activeIds = res.activeIds;
            notifyCallbacks('removed');
        }).catch(function () {
            options.activeIds.push(id);
            notifyCallbacks('remove-failed');
        }).always(function () {
            _requests[id] = null;
        });

        return _requests[id];
    };

    let callbacks = [];
    const onStateChange = function(callback) {
        callbacks.push(callback);
    };
    const notifyCallbacks = function(eventname) {
        callbacks.forEach(fnc => fnc(options.activeIds));
        if(eventname){
            triggerEvent(eventname)
        }
    };
    const getState = function () {
        return options.activeIds;
    };

    const triggerEvent = function(eventname){
        $(document).trigger(eventname + '.wishlist' , {list: selectors.wishlistId, activeIds: options.activeIds})
    }

    const renderWishlist = function ($scope = $('body')) {
        triggerEvent("renderd")
        let $wishButtons = $scope.find(selectors.item);
        let activeButtonCount = 0;

        // First, count the number of active buttons
        $wishButtons.each((_, el) => {
            let $button = $(el),
                id = $button.data(selectors.itemId),
                isInList = options.activeIds.includes(id);

            if (isInList) {
                activeButtonCount++;
            }
        });

        // Check if the wishlist items count is 4
        const wishlistItemsCount = $('.js-wishlist__items-count')[0].textContent;
        const isWishlistFull = parseInt(wishlistItemsCount, 10) >= 4;

        // Then, apply the appropriate classes and disable logic
        $wishButtons.each((_, el) => {
            let $button = $(el),
                id = $button.data(selectors.itemId),
                isInList = options.activeIds.includes(id);

            if (isInList) {
                $button.attr('title', translate('wishlist.remove'))
                    .addClass(selectors.itemActiveClass);
            } else {
                $button.attr('title', translate('wishlist.add'))
                    .removeClass(selectors.itemActiveClass)
            }

            // Disable button if more than 4 items are in the wishlist or if the wishlist is full
            if ((activeButtonCount >= 4 || isWishlistFull) && !isInList) {
                $button.attr('disabled', 'disabled');
            } else {
                $button.removeAttr('disabled');
            }
        })
    };


    const init = function () {
        $('body').on('click touchstart', selectors.item, function() {
            const $wishlistBtn = $(this);
            const id = $wishlistBtn.data(selectors.itemId);

            if(Array.isArray(options.activeIds) && options.activeIds.includes(id)) {
                removeFromWishlist(id);
            }else{
                addToWishlist(id);
            }
        })
        onStateChange(()  => renderWishlist());
    };

    return {
        init: init,
        initInScope: renderWishlist,
        renderWishlist: renderWishlist,
        add: addToWishlist,
        remove: removeFromWishlist,
        getState: getState,
        onstatechange: onStateChange
    }
}